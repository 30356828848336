import React from 'react';

class Envelope extends React.Component {
    render() {
        return (
            <div className="envelope">
                <svg id="94c1f45f-2d92-458e-bbfe-f6c69da92e14" 
                data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
                width="1034.95" 
                height="831.61" 
                viewBox="0 0 1034.95 831.61">
                    <defs>
                        <linearGradient id="eb1b6581-0e6c-46f0-8ed9-49203ffc7122" x1="924.49" y1="836.62" x2="924.49" y2="217.05" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="gray" stop-opacity="0.25"/>
                            <stop offset="0.54" stop-color="gray" stop-opacity="0.12"/>
                            <stop offset="1" stop-color="gray" stop-opacity="0.1"/>
                        </linearGradient>
                    </defs>
                    <title>Contact us!</title>
                    <path d="M683.84,129.27C617.49,127.36,554.3,109.52,494,90S373.84,48.89,309.08,38.55c-41.65-6.65-89.28-7.59-122.84,11C153.94,67.44,143.51,98.33,137.9,127c-4.23,21.56-6.71,44.26,4.86,64.44,8,14,22.29,25.79,32.15,39.21,34.3,46.71,10.06,104.3-27.12,149.9-17.44,21.39-37.67,41.8-51.13,64.57S77,494,88.75,517.2c11.67,23,39.48,40.31,69.61,52.48,61.19,24.7,133.3,31.77,203.61,35.78,155.64,8.86,312.12,5,468.18,1.18,57.75-1.42,115.76-2.86,172.58-10.3,31.55-4.13,64.12-10.68,87-26.5,29.08-20.08,36.28-54.09,16.8-79.27-32.68-42.24-123-52.73-145.89-98.06-12.58-24.95.34-52.75,18.61-75.89,39.2-49.64,104.91-93.19,108.37-149.93,2.37-39-29.21-78-78.05-96.44-51.19-19.33-122.18-16.9-160,15.1C810.76,118.29,742.42,131,683.84,129.27Z" transform="translate(-82.53 -34.2)" fill="#489ae7" opacity="0.1"/><ellipse cx="435.45" cy="802.29" rx="290.75" ry="29.32" fill="#489ae7" opacity="0.1"/>
                    <path d="M252,833.31S239.1,760.22,191.22,724c-20.09-15.2-34.79-35.87-41.21-59.41a179.25,179.25,0,0,1-5.78-34.1" transform="translate(-82.53 -34.2)" fill="none" stroke="#535461" stroke-miterlimit="10" stroke-width="2"/>
                    
                    <path d="M114.21,607.49c4,7.83,30.73,23.68,30.73,23.68s3.36-29.61-.67-37.45-14-11.11-22.32-7.31S110.18,599.65,114.21,607.49Z" transform="translate(-82.53 -34.2)" fill="#dcdfed"/>
                    <path d="M111.66,662.28c7.9,4.51,39.44,4.11,39.44,4.11S136.87,639.81,129,635.3s-18.16-2.13-22.94,5.32S103.77,657.77,111.66,662.28Z" transform="translate(-82.53 -34.2)" fill="#dcdfed"/>
                    <path d="M150.87,736c9.22.13,36.23-15.25,36.23-15.25s-26.49-16.16-35.72-16.3-16.82,6.82-17,15.53S141.64,735.84,150.87,736Z" transform="translate(-82.53 -34.2)" fill="#dcdfed"/>
                    <path d="M193.83,785.43c9.06,1.61,38.44-9.25,38.44-9.25S209.05,756,200,754.42s-17.8,4-19.5,12.6S184.76,783.83,193.83,785.43Z" transform="translate(-82.53 -34.2)" fill="#dcdfed"/>
                    <path d="M183.44,653.45c-3.15,8.19-28,26.55-28,26.55s-6.58-29.12-3.43-37.31,12.74-12.42,21.41-9.45S186.59,645.27,183.44,653.45Z" transform="translate(-82.53 -34.2)" fill="#dcdfed"/>
                    <path d="M232.91,711.89c-5.6,6.93-35.12,17.43-35.12,17.43s3-29.65,8.56-36.57a17.38,17.38,0,0,1,23.42-3A15.2,15.2,0,0,1,232.91,711.89Z" transform="translate(-82.53 -34.2)" fill="#dcdfed"/>
                    <path d="M272.73,774.92c-4.28,7.72-31.49,22.78-31.49,22.78s-2.39-29.69,1.89-37.41,14.37-10.7,22.55-6.66S277,767.2,272.73,774.92Z" transform="translate(-82.53 -34.2)" fill="#dcdfed"/>
                    
                    <path d="M114.21,607.49c4,7.83,30.73,23.68,30.73,23.68s3.36-29.61-.67-37.45-14-11.11-22.32-7.31S110.18,599.65,114.21,607.49Z" transform="translate(-82.53 -34.2)" opacity="0.25"/>
                    <path d="M111.66,662.28c7.9,4.51,39.44,4.11,39.44,4.11S136.87,639.81,129,635.3s-18.16-2.13-22.94,5.32S103.77,657.77,111.66,662.28Z" transform="translate(-82.53 -34.2)" opacity="0.25"/>
                    <path d="M150.87,736c9.22.13,36.23-15.25,36.23-15.25s-26.49-16.16-35.72-16.3-16.82,6.82-17,15.53S141.64,735.84,150.87,736Z" transform="translate(-82.53 -34.2)" opacity="0.25"/>
                    <path d="M193.83,785.43c9.06,1.61,38.44-9.25,38.44-9.25S209.05,756,200,754.42s-17.8,4-19.5,12.6S184.76,783.83,193.83,785.43Z" transform="translate(-82.53 -34.2)" opacity="0.25"/>
                    <path d="M183.44,653.45c-3.15,8.19-28,26.55-28,26.55s-6.58-29.12-3.43-37.31,12.74-12.42,21.41-9.45S186.59,645.27,183.44,653.45Z" transform="translate(-82.53 -34.2)" opacity="0.25"/>
                    <path d="M232.91,711.89c-5.6,6.93-35.12,17.43-35.12,17.43s3-29.65,8.56-36.57a17.38,17.38,0,0,1,23.42-3A15.2,15.2,0,0,1,232.91,711.89Z" transform="translate(-82.53 -34.2)" opacity="0.25"/>
                    <path d="M272.73,774.92c-4.28,7.72-31.49,22.78-31.49,22.78s-2.39-29.69,1.89-37.41,14.37-10.7,22.55-6.66S277,767.2,272.73,774.92Z" transform="translate(-82.53 -34.2)" opacity="0.25"/>
                    <path d="M250.38,831.39s19.57-71.76-8.65-123.2c-11.84-21.58-16.41-46.1-12.17-70.08a180,180,0,0,1,9.35-33.38" transform="translate(-82.53 -34.2)" fill="none" stroke="#535461" stroke-miterlimit="10" stroke-width="2"/>
                    
                    <path d="M221.33,572.22c.32,8.7,17.93,33.41,17.93,33.41s15.78-25.79,15.46-34.5-8.05-15.52-17.27-15.22S221,563.51,221.33,572.22Z" transform="translate(-82.53 -34.2)" fill="#FF8E00"/>
                    <path d="M195.49,621.34c5.28,7.14,34.28,18.84,34.28,18.84s-1.6-29.74-6.88-36.89a17.35,17.35,0,0,0-23.26-3.91A15.23,15.23,0,0,0,195.49,621.34Z" transform="translate(-82.53 -34.2)" fill="#FF8E00"/>
                    <path d="M199.69,703.71c8.38,3.65,39.68-.07,39.68-.07s-17.29-24.92-25.67-28.57-18.3-.2-22.17,7.71S191.32,700.06,199.69,703.71Z" transform="translate(-82.53 -34.2)" fill="#FF8E00"/>
                    <path d="M217.74,765.36c7.61,4.94,39.12,6.25,39.12,6.25s-12.58-27.31-20.18-32.24a17.33,17.33,0,0,0-23.23,4A15.23,15.23,0,0,0,217.74,765.36Z" transform="translate(-82.53 -34.2)" fill="#FF8E00"/>
                    <path d="M264.9,640.73c-6.4,6.28-37,13.57-37,13.57s6.49-29.15,12.88-35.43a17.42,17.42,0,0,1,23.63-.44A15.16,15.16,0,0,1,264.9,640.73Z" transform="translate(-82.53 -34.2)" fill="#FF8E00"/>
                    <path d="M285,713.08c-8.09,4.19-39.58,2.5-39.58,2.5s15.43-26,23.52-30.16,18.25-1.39,22.68,6.25S293.13,708.89,285,713.08Z" transform="translate(-82.53 -34.2)" fill="#FF8E00"/>
                    <path d="M294.39,785.94c-7.23,5.42-38.57,8.78-38.57,8.78s10.56-28.07,17.79-33.49a17.38,17.38,0,0,1,23.47,2.55A15.19,15.19,0,0,1,294.39,785.94Z" transform="translate(-82.53 -34.2)" fill="#FF8E00"/>
                    
                    <path d="M582.08,248.59,320.57,418.25l170.66,307.8a11.91,11.91,0,0,0,7.6,5.8l154.63,37.72A11.92,11.92,0,0,0,667,763.16l76.7-159.35,84.06-112.37-199.48-236A35.35,35.35,0,0,0,582.08,248.59Z" transform="translate(-82.53 -34.2)" fill="#dcdfed"/>
                    <path d="M830.42,492.77l-.74,1-49.24,65.83-.89,1.19-33.19,44.37-65.6,136.31-1.84,3.81-9.26,19.23a11.92,11.92,0,0,1-13.56,6.41L501.47,733.18a11.89,11.89,0,0,1-7.6-5.8L479,700.61l-2.67-4.82L369,502.13l-.92-1.65-44.86-80.9,5.42-3.52,41.09-26.65.95-.62,86.53-56.14,5.46-3.54,122.05-79.19a35.34,35.34,0,0,1,46.22,6.84l91,107.7,4,4.8,66.05,78.14.36.43,33.21,39.28,4.18,4.95Z" transform="translate(-82.53 -34.2)" opacity="0.1"/>
                    <path d="M789.79,446.5l-8.06,56L779.3,519.4l-1.39,36-.11,2.81-7.23,188.1a11.93,11.93,0,0,1-14.51,11.17l-77.94-17.4-.84-.19L477.39,695.28l-3.67-.82L363.83,669.92l2.52-169.12.08-5.65.76-51.19.84-56.5,86.53-56.14,5.46-3.54c80.26,8.23,187,24.06,259.31,35.35l4,4.8,66,78.14Z" transform="translate(-82.53 -34.2)" opacity="0.1"/>
                    <path d="M798.92,380.1l-9.49,66-8.7,60.44L778.3,523.4l-1.39,36-7.34,190.91a11.93,11.93,0,0,1-14.51,11.17l-78.78-17.59L476.39,699.28,362.83,673.92l2.6-174.77.76-51.19.89-59.88.91-61.59c18.78-.89,49.68,1.12,86.57,4.83,82.6,8.29,195.2,25.06,268.82,36.61C768.46,375,798.92,380.1,798.92,380.1Z" transform="translate(-82.53 -34.2)" fill="#489ae7"/>
                    <path d="M823,485.78l-42.27,20.73L565,612.31,366.19,448,326,414.73l-5.42,3.52,44.86,80.9-2.6,174.77,113.56,25.36,14.84,26.77a11.89,11.89,0,0,0,7.6,5.8l154.63,37.72A11.92,11.92,0,0,0,667,763.16l9.26-19.23,78.78,17.59a11.93,11.93,0,0,0,14.51-11.17l7.34-190.91,50.13-67,.14-1.69Z" transform="translate(-82.53 -34.2)" opacity="0.1"/>
                    <path d="M320.57,418.25,302.74,723.58a11.92,11.92,0,0,0,9.31,12.33L785.57,841.06A11.92,11.92,0,0,0,800,830.39l27.75-338.95L565,620.31Z" transform="translate(-82.53 -34.2)" fill="#dcdfed"/>
                    <path d="M306,730.57,552,578.8a18.06,18.06,0,0,1,23,3.6L795.18,839.19c.8.92,1.19.26,0,0L306,730.57C304.6,730.26,304.78,731.3,306,730.57Z" transform="translate(-82.53 -34.2)" opacity="0.1"/>
                    <path d="M307.76,730.32,551.22,583.25a19.09,19.09,0,0,1,24.28,3.82L793.69,838a2.63,2.63,0,0,1-2.55,4.29L308.55,735.13A2.62,2.62,0,0,1,307.76,730.32Z" transform="translate(-82.53 -34.2)" fill="#e3e5f1"/>
                </svg>
            </div>
        );
    }
}

export default Envelope;